import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { CropsComponent } from "../Cards/Crops";
import { DashboardMediaComponent } from "../Cards/DashboardMedia";
import { HybridsComponent } from "../Cards/Hybrids";
import PublishCard from "../Cards/Publish";
import PushNotificationCard from "../Cards/PushNotification";
import RetailersComponent from "../Cards/Retailers";
import CountrySelection from "../Components/CountrySelection";
import { CpProductsList } from "../Components/CpProducts";
import { Languages } from "../Components/Languages/Language";
import PlantixMapProduct from "../Components/PlantixMapProduct";
import PlantixCPProducts from "../Components/PlantixCPProducts";
import QueryResponse from "../Components/QueryResponse";
import { SuccessPage } from "../Components/SuccessPage/success";
import Threats from "../Components/Threats";
import { RootState } from "../redux/reducers";
import { RoutePaths } from "../Constants/routeConstants";
const PageNotFound = lazy(() => import("./../Components/404"));
const Home = lazy(() => import('./../Components/Home'));
const NewQueryResponse = lazy(() => import('./../Components/NewQueryResponse'));
const AuthRoutes = () => {
  const storeProps = useSelector((state: RootState) => {
    return {
      baseUrl: state.auth.baseUrl,
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });
  // const loadAppMetaData = () => {
  //     Promise.all([fetchLanguagesList(dispatch), fetchCropsList(dispatch), fetchNotificationTypes(dispatch)])
  //         .then((values) => {
  //             console.log('******* loading app metadata');
  //             console.log(values);
  //         })
  //         .catch((err) => {

  //         }).finally(() => {
  //             toggleAppLoader(false);
  //         });
  // };
  // useEffect(() => {
  //     loadAppMetaData();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Routes>
      {storeProps.baseUrl === "" ? (
        <Route
          path={RoutePaths.PageNotFound}
          element={
            <Suspense fallback={null}>
              <CountrySelection />
            </Suspense>
          }
        />
      ) : (
        <>
          <Route
            path={RoutePaths.Dashboard}
            element={
              <Suspense fallback={null}>
                <Home onSelectService={() => { }} />
              </Suspense>
            }
          />
          <Route
            path={RoutePaths.Crops}
            element={
              <Suspense fallback={null}>
                {/* <Home onSelectService={() => { }} /> */}
                <CropsComponent />
              </Suspense>
            }
          />

          <Route
            path={RoutePaths.Threats}
            element={
              <Suspense fallback={null}>
                <Threats />
              </Suspense>
            }
          />
          <Route
            path={RoutePaths.CPProducts}
            element={
              <Suspense fallback={null}>
                <CpProductsList />
              </Suspense>
            }
          />

          <Route
            path={RoutePaths.Hybrids}
            element={
              <Suspense fallback={null}>
                <HybridsComponent />
              </Suspense>
            }
          />
          <Route
            path={RoutePaths.RetailersAndNurseries}
            element={
              <Suspense fallback={null}>
                <RetailersComponent />
              </Suspense>
            }
          />
          < Route
            path={RoutePaths.Languages}
            element={
              < Suspense fallback={null} >
                <Languages title={"Languages"} />
              </Suspense >
            }
          />

          < Route
            path={RoutePaths.PushNotifications}
            element={
              < Suspense fallback={null} >
                <PushNotificationCard title={"Push Notifications"} />
              </Suspense >
            }
          />

          < Route
            path={RoutePaths.UserAndRoles}
            element={
              < Suspense fallback={null} >
                <Home onSelectService={() => { }} />
              </Suspense >
            }
          />
          
            < Route
              path={RoutePaths.QueryResponse}
              element={
                < Suspense fallback={null} >
                  <QueryResponse />
                </Suspense >
              }
            />
          < Route
            path={RoutePaths.DashboardMedia}
            element={
              < Suspense fallback={null} >
                <DashboardMediaComponent />
              </Suspense >
            }
          />
          < Route
            path={RoutePaths.Publish}
            element={
              < Suspense fallback={null} >
                <PublishCard title="Publish Data" />
              </Suspense >
            }
          />
          < Route
            path={RoutePaths.PlantixMapProduct}
            element={
              < Suspense fallback={null} >
                <PlantixMapProduct />
              </Suspense >
            }
          />
          <Route
            path={RoutePaths.successPage}
            element={<SuccessPage />}
          />
          <Route
            path={RoutePaths.PageNotFound}
            element={
              < Suspense fallback={null} >
                <PageNotFound />
              </Suspense >
            }
          />
          <Route
            path={RoutePaths.PlantixCPProducts}
            element={
              <Suspense fallback={null}>
                <PlantixCPProducts />
              </Suspense>
            }
          />

          <Route
            path={RoutePaths.NewQueryResponse}
            element={
              <Suspense fallback={null}>
                <NewQueryResponse />
              </Suspense>
            }
          />
        </>
      )}
    </Routes >
  );
};

export default AuthRoutes;
