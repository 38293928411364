import { Box, Stack, TableContainer } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import { FilterType } from "../../Cards/PushNotification";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import FilterAndSort from "../../Common/FilterAndSort";
import Loader from "../../Common/Loader";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import States, { TYPE_NO_PUSH, TYPE_PUSH_APPROVED } from "../../Common/States";
import { RequestMethods, SORT_OPTIONS } from "../../Constants/enums";
import { IRequestBody } from "../../Constants/interfaces";
import { UPDATED_SELECTED_MODULE } from "../../redux/action/actions-constants";
import { RootState } from "../../redux/reducers";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { CPCreateEdit } from "../CpProducts/AddCpProduct";
import ViewCP from "../CpProducts/ViewCpProducts/ViewCpProducts";
import ViewPlantixCPProduct from "../PlantixCPProducts/ViewPlantixCPProduct";
import ViewPlantixMapProduct from "../PlantixMapProduct/ViewPlantixMapProduct";
import { CreateOrModifyCrop } from "../Crop/AddEditCrop";
import ViewCrop from "../Crop/ViewCrop/ViewCrop";
import AddEditDashboard from "../DashboardMedia/AddEditDashboardModal";
import ViewDashboard from "../DashboardMedia/ViewDashboardMedia";
import ViewHybrid from "../Hybrids/ViewHybrid/ViewHybrid";
import {
  DeletePublish,
  getPublishableData,
} from "./PublishApiCalls/PublishApiCalls";
import { NotificationType } from "../PushNotifications/AddEditNotification";
import ViewNotification from "../PushNotifications/ViewNotification";
import CreateNewRetailerModal from "../RetailersAndNurseries/Modal/CreateNewRetailerModal";
import ViewRetailerModal from "../RetailersAndNurseries/Modal/ViewRetailerModal";
import { AddNewThreat } from "../Threats/AddEditThreat";
import ViewThreat from "../Threats/ViewThreat";
import { ActionType, PublishModuleDD } from "../../Common/Constants";
import { CreateOrModifyHybrid } from "../Hybrids/AddEditHybrid";
import { getPublishedHistory } from "./api_calls";
import {
  PlantixPeatidMappingColumns,
  PublishColumnType,
  PublishedColumn,
  PublishedHistory,
  PublishedPeatidMappingHistory,
  RetailerNurseriesColumns,
} from "./columnsData";
import PublishView from "./PublishView";
import { useStyles } from "./style";
import CreateOrEditModal from "../PlantixCPProducts/ModalCreatePopUp/CreateOrEditModal";
import { Select } from "../../Common/Form";
import { usePublishProgressContext } from "../../Common/ProgressProvider";
import { checkFeatureModifiedBy } from "../../Api/generic_apicalls";

type PublishType = {
  title: string;
  isLoading?: boolean;
  data: Array<any>;
  stateType?: typeof TYPE_NO_PUSH | typeof TYPE_PUSH_APPROVED;
  showActionProgress?: boolean;
  showCreateProgress?: boolean;
  filters?: FilterType;
  onSelectAll?: Function;
  onDeselectAll?: Function;
  onSelectPush?: Function;
  onCreatePush?: Function;
  onDeletePush?: Function;
  onChangeRowsPerPage?: Function;
  onRefresh?: Function;
  onSearch?: Function;
  onSort?: Function;
  onPageChange?: Function;
  onChangeFilters?: Function;
};

type OptionFilterAndSort = {
  showLanguageSelection: boolean;
  showCropSelection: boolean;
  showRetailerCategoryType?: boolean;
  showRetailerType?: boolean;
};

const Published: React.FC<PublishType> = (props: any) => {
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLanguageCode: state.auth.languageCode,
      languages: state.generic.ListOfLanguages.languageList,
      countryCode: state.auth.countryCode,
      modules: state.generic.ListOfModules,
      selectedModule: state.publishModuleSelectedReducer.selectedModule,
    };
  });

  const dispatch = useDispatch();
  const { listPublishProgress } = usePublishProgressContext();

  // classes
  const classes = useStyles();
  const [selectedModule, updateSelcetedModule] = useState<string>(
    storeData.selectedModule
  );
  const [modules, setModules] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [publishableData, setPublishableData] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showCreatePushNotification, setShowCreatePushNotification] =
    useState<boolean>(false);
  const [showViewNotification, setShowViewNotification] = useState(false);
  const [singlePublishRecord, setSinglePublishRecord] = useState<any>({});
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedFiltersCrop, setSelectedFiltersCrop] = useState<string[]>([]);
  const [selectedFilterRetailerType, setSelectedFilterRetailerType] = useState<
    string[]
  >([]);
  const [selectedFilterRetailerCategory, setSelectedFilterRetailerCategory] =
    useState<string[]>([]);
  const [selectedSortOptions, setSelectedSortOptions] = useState<number>(1);
  const [successfail, setSuccessfail] = useState<{
    pageFail: Boolean;
    pageSuccess: Boolean;
  }>({ pageFail: false, pageSuccess: false });
  const [showViewRetailer, setShowViewRetailer] = useState<boolean>(false);
  const [showEditRetailer, setShowEditRetailer] = useState<boolean>(false);
  const [featureModifiedBy, setFeatureModifiedBy] = useState<boolean | null>(
    null
  );
  const [activeColumns, setActiveColumns] = useState<Array<PublishColumnType>>(
    []
  );
  const [notificationInView, setNotificationInView] =
    useState<NotificationType>({
      notificationTypeName: "",
      languageId: undefined,
      stateId: undefined,
      //districtIds: [],
      startDate: null,
      endDate: null,
      frequency: "",
      message: "",
      sendAsSMS: false,
      dayOfWeek: "",
      dayOfMonth: "",
      filteredCrops: [],
      stateNames: [],
      time: null,
      selectedDistrictObj: [],
      smsCost: "",
      title: "",
      hyperlink: "",
      id: 0,
    });

  const [cropView, setCropView] = useState<any>({});
  const [cropEdit, setCropEdit] = useState<any>({});

  const [cpView, setCpView] = useState<any>({});
  const [cpEdit, setCpEdit] = useState<any>({});

  const [hybridView, setHybridView] = useState<any>({});
  const [hybidEdit, setHybridEdit] = useState<any>({});

  const [selectedThreatInfo, setSelectedThreatInfo] = useState<any>({});
  const [showThreatViewModal, toggleThreatViewModal] = useState(false);
  const [threatEdit, setThreatInfo] = useState<any>({});
  const [showThreatCreateModal, toggleThreatEditModal] = useState(false);
  const [selectedDashboardInfo, setSelectedDashboardInfo] = useState<any>({});
  const [showDashboardViewModal, toggleDashboardViewModal] = useState(false);
  const [showDashboardEditModal, toggleDashboardEditView] =
    useState<boolean>(false);

  const [plantixCpView, setPlantixCpView] = useState<any>({});
  const [plantixCpEdit, setPlantixCpEdit] = useState<any>({});

  const [plantixMapView, setPlantixMapView] = useState<any>({});

  /**
   * @description A function to trigger an api call to fetch
   * @param limit number
   * @returns
   */

  const fetchPublishable = (): void => {
    if (!selectedModule) return;
    let endpoints: string = api_endpoints.publishable_data.replace(
      "__DATATYPE__",
      selectedModule === "Plantix Map Product"
        ? "Plantix Peatid Mapping"
        : selectedModule
    );
    const apiData = {
      domain: base_url,
      endPoint: endpoints,
      payLoad: { pageNo: currentPage, pageSize: limit, sort: "ASC" },
    } as IRequestBody;
    if (searchValue) {
      apiData.payLoad.searchKey = searchValue.toUpperCase();
    }
    if (selectedFilters.length > 0) {
      apiData.payLoad.languageIds = selectedFilters
        .map((filterObj: string) => parseInt(filterObj.split(":::")[1]))
        .join(",");
    }
    if (selectedFiltersCrop.length > 0) {
      apiData.payLoad.crop = selectedFiltersCrop
        .map((filterObj: any) => filterObj.split(":::")[1])
        .join(",");
    }
    if (selectedFilterRetailerCategory.length > 0) {
      apiData.payLoad.categories = selectedFilterRetailerCategory.join(",");
    }
    if (selectedFilterRetailerType.length > 0) {
      apiData.payLoad.types = selectedFilterRetailerType.join(",");
    }
    if (selectedSortOptions >= 0) {
      apiData.payLoad.sort =
        selectedSortOptions === 0 ? SORT_OPTIONS.asc : SORT_OPTIONS.dsc;
    }
    console.log(selectedFilterRetailerCategory, selectedFilterRetailerType);
    getPublishableData(apiData, successCallBack, errorCallback);
  };

  const fetchPublishedHistory = () => {
    if (!selectedModule) return;
    const endpoints = api_endpoints.published_history.replace(
      "__DATATYPE__",
      selectedModule === "Plantix Map Product"
        ? "Plantix Peatid Mapping"
        : selectedModule
    );
    const apiData = {
      domain: base_url,
      endPoint: endpoints,
      payLoad: {
        pageNo: currentPage,
        pageSize: limit,
        searchKey: searchValue.toUpperCase(),
      },
    } as IRequestBody;
    if (selectedFilters.length > 0) {
      apiData.payLoad.languageIds = selectedFilters
        .map((filterObj: string) => parseInt(filterObj.split(":::")[1]))
        .join(",");
    }
    if (selectedSortOptions >= 0) {
      apiData.payLoad.sort =
        selectedSortOptions === 0 ? SORT_OPTIONS.asc : SORT_OPTIONS.dsc;
    }
    if (selectedFilterRetailerCategory.length > 0) {
      apiData.payLoad.categories = selectedFilterRetailerCategory.join(",");
    }
    if (selectedFilterRetailerType.length > 0) {
      apiData.payLoad.types = selectedFilterRetailerType.join(",");
    }
    getPublishedHistory(apiData, successCallBack, errorCallback);
  };

  const successCallBack = (response: any, method: RequestMethods) => {
    let totalRecords = response.data.totalRecords;
    setTotalRecords(totalRecords);
    let updatedData = response.data.publishDataDtoList.map((row: any) => {
      /**updating notification data according to table columns */
      return {
        ...row,
        modifiedDate: row?.modifiedDate
          ? moment(row?.modifiedDate).add(330, "m").format("YYYY-MM-DD hh:mm a")
          : "N/A",
      };
    });
    setPublishableData(updatedData);
  };

  /**
   * @description A callback function triggered when an api is failed
   */
  const errorCallback = () => {
    TriggerToastMessage("Unable to process your request", "error");
  };

  /**
   * @description A Handler function, which updates the selected number of rows per page
   * @param value number
   */

  // const editRowClickHandler = (rowData: LanguageObjProps) => {
  //     setPublishableData({ ...rowData });
  //     toggleAddLanguage(true);
  // };

  /**
   * @description A function which will reset current page
   * if currentPage === 0, it will trigger a method to load languages
   */
  const resetCurrentPage = () => {
    if (currentPage === 0) {
      activeTab === 0 ? fetchPublishable() : fetchPublishedHistory();
    } else {
      setCurrentPage(0);
    }
  };

  // handlers
  const handleSearchValueChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleCloseCreatePushNotification = () => {
    setShowCreatePushNotification(false);
  };

  const deletePublishApi = (id: number): void => {
    if (confirm("Are you sure to delete this record?")) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      /**
       * end point is changed by sivakiran on 08/09/2022
       * delete api is made generic in publish module
       */
      apiData.endPoint = api_endpoints.publishable_data.replace(
        "__DATATYPE__",
        selectedModule === "Plantix Map Product"
          ? "Plantix Peatid Mapping"
          : selectedModule
      );
      apiData.endPoint = `${apiData.endPoint}?dataId=${id}`;
      // apiData.payLoad = {
      //   listOfIds: [id]
      // };
      DeletePublish(apiData, successDeleteCallBack, errorDeleteCallback);
    }
  };

  const successDeleteCallBack = (response: any, method: RequestMethods) => {
    resetCurrentPage();
  };
  const errorDeleteCallback = () => {
    TriggerToastMessage("could not delete", "error");
  };
  /** This method will handle all view click actions */
  const handleModuleWiseView = (
    details: any,
    selectedPublishModule: string | undefined
  ) => {
    if (details) {
      switch (selectedPublishModule) {
        case PublishModuleDD.PUSH_NOTIFICATIONS:
          setShowViewNotification(true);
          setNotificationInView(details);
          break;
        case PublishModuleDD.CROPS:
          setCropView(details);
          break;
        case PublishModuleDD.THREATS:
          setSelectedThreatInfo(details);
          toggleThreatViewModal(true);
          break;
        case PublishModuleDD.CP_PRODUCTS:
          setCpView(details);
          break;
        case PublishModuleDD.DASHBOARD_MEDIA:
          setSelectedDashboardInfo(details);
          toggleDashboardViewModal(true);
          break;
        case PublishModuleDD.HYBRIDS:
          setHybridView(details);
          break;
        case PublishModuleDD.RETAILERS_NURSUREY:
          setSelectedDashboardInfo(details);
          setShowViewRetailer(true);
          break;
        case PublishModuleDD.PLANTIX_CP_PRODUCTS:
          setPlantixCpView(details);
          break;
        case PublishModuleDD.PLANTIX_MAP_PRODUCTS:
          setPlantixMapView(details);
          break;
        default:
          TriggerToastMessage(`${selectedPublishModule} not matched`, "info");
      }
    }
  };
  /** This method will handle all edit click actions from view modals*/
  const handleModuleWiseEdit = (
    details: any,
    selectedPublishModule: string | undefined
  ) => {
    const handleEditAction = (module: string, editFunction: Function) => {
      if (details) {
        editFunction(details);
      }
    };

    switch (selectedPublishModule) {
      case PublishModuleDD.CROPS:
        handleEditAction(selectedPublishModule, setCropEdit);
        break;
      case PublishModuleDD.THREATS:
        handleEditAction(selectedPublishModule, setThreatInfo);
        break;
      case PublishModuleDD.CP_PRODUCTS:
        handleEditAction(selectedPublishModule, setCpEdit);
        break;
      case PublishModuleDD.HYBRIDS:
        handleEditAction(selectedPublishModule, setHybridEdit);
        break;
      case PublishModuleDD.DASHBOARD_MEDIA:
        handleEditAction(selectedPublishModule, (details: any) => {
          setSelectedDashboardInfo(details);
          toggleDashboardEditView(true);
        });
        break;
      case PublishModuleDD.PLANTIX_CP_PRODUCTS:
        handleEditAction(selectedPublishModule, setPlantixCpEdit);
        break;
      default:
        TriggerToastMessage(`${selectedPublishModule} not matched`, "info");
    }
  };
  /** Default action click handler: like view, edit, publish and delete actions */
  const handleActionClick = (
    action: string,
    details?: any,
    selectedPublishModule?: string
  ) => {
    switch (action) {
      case ActionType.VIEW:
        handleModuleWiseView(details, selectedPublishModule);
        break;
      case ActionType.UPDATE: {
        handleModuleWiseEdit(details, selectedPublishModule);
        break;
      }
      case "Delete":
        deletePublishApi(details.id);
        break;
      case "Publish":
        if (details) {
          setSinglePublishRecord(details);
        }
        setShowCreatePushNotification(true);
        break;
      case "Back":
        return props.onRefresh();
    }
  };

  const toggleTabView = (event: React.SyntheticEvent, newValue: number) => {
    setSearchValue("");
    setSelectedSortOptions(1);
    setSelectedFilters([]);
    setSelectedFiltersCrop([]);
    setActiveTab(newValue);
  };

  const handleViewPublish = () => {
    setShowViewNotification(false);
  };
  /**
   * @description A Handler function, which updates the selected number of rows per page
   * @param value number
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setLimit(value);
  };
  /**
   * @description Delete button wehenever checkbox will be selected
   */
  const processFilters = (selectedFilterProperties: string[]) => {
    // const selectedLanguageIdArray: number[] = [];
    // selectedFilterProperties.map((filterObj: string) => {
    //   selectedLanguageIdArray.push(parseInt(filterObj.split(":::")[1]));
    // });
    setSelectedFilters(selectedFilterProperties);
  };

  const processFiltersCrop = (selectedFilterProperties: string[]) => {
    // const crops = selectedFilterProperties
    //   .map((filterObj: any) => {
    //     return filterObj.split(":::")[1];
    //   })
    //   .join(",");
    setSelectedFiltersCrop(selectedFilterProperties);
  };

  const processSort = (selectedSortPropoerties: any) => {
    setSelectedSortOptions(parseInt(selectedSortPropoerties, 10));
  };

  const handleOptionsFilterAndSort = (
    _selectedModule: string
  ): OptionFilterAndSort => {
    let obj: OptionFilterAndSort = {
      showLanguageSelection: true,
      showCropSelection: false,
    };
    switch (_selectedModule) {
      case PublishModuleDD.RETAILERS_NURSUREY:
        obj = {
          showRetailerCategoryType: true,
          showRetailerType: true,
          showLanguageSelection: false,
          showCropSelection: false,
        };
        break;
      case PublishModuleDD.PLANTIX_MAP_PRODUCTS:
        obj = {
          showLanguageSelection: false,
          showCropSelection: true,
        };
        break;
      default:
        break;
    }
    return obj;
  };

  /** render module wise view modal START */
  /** VIEW : PUSH NOTIFICATION */
  const renderViewPushNotificationModal = () => {
    return (
      <ViewNotification
        notificationDetails={notificationInView}
        onCloseViewNotification={handleViewPublish}
      />
    );
  };
  /** VIEW : CROP */
  const renderViewCropModal = () => {
    return (
      <ViewCrop
        id={cropView.id}
        lngId={undefined} /**here, languageId will be undefined */
        onCloseViewCrop={() => setCropView({})}
        onEditClick={(clickAction: string, details: any) =>
          handleActionClick(clickAction, details, PublishModuleDD.CROPS)
        }
      />
    );
  };
  /** VIEW : THREAT */
  const renderViewThreatDataModal = () => {
    return (
      <ViewThreat
        isDraftRecord={true}
        details={selectedThreatInfo}
        canShowEditButton={activeTab === 0}
        onCloseViewNotification={() => toggleThreatViewModal(false)}
        editHandler={handleThreatEditFromView}
      />
    );
  };
  /** VIEW : CP PRODUCT */
  const renderViewCPModal = () => {
    return (
      <ViewCP
        id={cpView.id}
        sysNm={cpView.title}
        lngId={undefined} /**here, languageId will be undefined */
        canShowEditButton={activeTab === 0}
        onCloseViewCrop={() => setCpView({})}
        onEditClick={(clickAction: string, details: any) =>
          handleActionClick(clickAction, details, PublishModuleDD.CP_PRODUCTS)
        }
      />
    );
  };
  const renderDashboardViewModal = () => {
    return (
      <ViewDashboard
        isDraftRecord={true}
        canShowEditButton={activeTab === 0}
        dashboardMediaDetails={selectedDashboardInfo}
        onEditClick={(clickAction: string, details: any) => {
          toggleDashboardViewModal(false);
          handleActionClick(
            ActionType.UPDATE,
            selectedDashboardInfo,
            PublishModuleDD.DASHBOARD_MEDIA
          );
        }}
        onCloseViewDashboard={() => {
          setSelectedDashboardInfo({});
          toggleDashboardViewModal(false);
        }}
      />
    );
  };

  const renderDashboardEditModal = () => {
    return (
      <>
        {
          <AddEditDashboard
            isDraftRecord={true}
            dashboardMediaDetails={selectedDashboardInfo}
            onCloseCreateDashboardMedia={() => toggleDashboardEditView(false)}
            action={ActionType.UPDATE}
            onCreateDashboard={() => {}}
            uniqueKey={0}
          />
        }
      </>
    );
  };

  /**VIEW : HYBRID */

  const renderViewHybridModal = () => {
    return (
      <ViewHybrid
        hybridViewID={hybridView.id}
        lngId={undefined} /**here, languageId will be undefined */
        canShowEditButton={activeTab === 0}
        onCloseViewHybrid={() => setHybridView({})}
        onEditClick={(clickAction: string, details: any) =>
          handleActionClick(clickAction, details, PublishModuleDD.HYBRIDS)
        }
      />
    );
  };

  /**VIEW : PLANTIX CP PRODUCT */

  const renderViewPlantixCPModal = () => {
    return (
      <ViewPlantixCPProduct
        id={plantixCpView.id}
        canShowEditButton={activeTab === 0}
        onClose={() => setPlantixCpView({})}
        onEditClick={(clickAction: string, details: any) => {
          handleActionClick(
            clickAction,
            details,
            PublishModuleDD.PLANTIX_CP_PRODUCTS
          );
        }}
      />
    );
  };

  /**VIEW : PLANTIX MAP PRODUCT */

  const renderViewPlantixMapModal = () => {
    return (
      <ViewPlantixMapProduct
        isOnPublish={true}
        selectedId={plantixMapView.id}
        canShowEditButton={activeTab === 0}
        onClose={() => setPlantixMapView({})}
        onEditClick={(clickAction: string, details: any) => {
          handleActionClick(
            clickAction,
            details,
            PublishModuleDD.PLANTIX_MAP_PRODUCTS
          );
        }}
      />
    );
  };

  /** render module wise view modal END */

  /** Module wise Edit popup: START */
  /** EDIT: CROP */
  const renderCreateEditCrop = () => {
    return (
      <CreateOrModifyCrop
        onClose={() => setCropEdit({})}
        isEdit={!!cropEdit.id}
        id={cropEdit.id}
        lngId={cropEdit.languageId}
        // actionType={
        //   cropEdit.languageCode !== storeData.primaryLanguageCode
        //     ? ActionType.ADD_TRANSLATION
        //     : ActionType.UPDATE
        // }
        actionType={ActionType.UPDATE}
        cropViewID={cropEdit.id}
      />
    );
  };
  /** EDIT: THREAT */
  const renderEditThreatModal = () => {
    let action = ActionType.UPDATE;
    // const checkIfPrimaryLanguageRecord = _.filter(storeData.languages, {
    //   languageName: selectedThreatInfo.languageName,
    // });
    // if (checkIfPrimaryLanguageRecord.length > 0) {
    //   action =
    //     checkIfPrimaryLanguageRecord[0].languageCode ===
    //     storeData.primaryLanguageCode
    //       ? ActionType.UPDATE
    //       : ActionType.ADD_TRANSLATION;
    // }
    return (
      <AddNewThreat
        notificationDetails={{}}
        onClose={() => {
          toggleThreatEditModal(false);
          setThreatInfo({});
        }}
        isEdit={true}
        isDraftRecord={true}
        details={selectedThreatInfo}
        actionType={action}
      />
    );
  };
  /** EDIT: CP PRODUCT */
  const renderCreateEditCP = () => {
    return (
      <CPCreateEdit
        onClose={() => setCpEdit({})}
        isEdit={true}
        lngId={cpEdit.languageId}
        // actionType={
        //   cpEdit.languageCode !== storeData.primaryLanguageCode
        //     ? ActionType.ADD_TRANSLATION
        //     : ActionType.UPDATE
        // }
        actionType={ActionType.UPDATE}
        cpViewID={cpEdit.id}
      />
    );
  };

  const renderHybridEdit = () => {
    return (
      <CreateOrModifyHybrid
        onClose={() => {
          fetchPublishable();
          setHybridEdit({});
        }}
        isEdit={true}
        lngId={undefined} /**here, languageId will be undefined */
        actionType={ActionType.UPDATE}
        // actionType={
        //   hybidEdit.languageCode !== storeData.primaryLanguageCode
        //     ? ActionType.ADD_TRANSLATION
        //     : ActionType.UPDATE
        // }
        hybridViewID={hybidEdit.id}
      />
    );
  };

  /** EDIT: PLANTIX CP PRODUCT */
  const renderCreateEditPlantixCP = () => {
    return (
      <CreateOrEditModal
        id={plantixCpEdit.id}
        showCreateOrEditModal={true}
        onShowCreateOrEditModal={(val: boolean) => setPlantixCpEdit({})}
        lngId={plantixCpEdit.languageId}
        // actionType={
        //   plantixCpEdit.languageCode !== storeData.primaryLanguageCode
        //     ? ActionType.ADD_TRANSLATION
        //     : ActionType.UPDATE
        // }
        actionType={ActionType.UPDATE}
        sysNm={plantixCpEdit.system}
      />
    );
  };

  const renderRetailerViewModal = () => {
    return (
      <ViewRetailerModal
        isDraftRecord={true}
        onRequestClose={() => setShowViewRetailer(false)}
        selectedRetailerInfo={selectedDashboardInfo}
        isPublishRetailer={activeTab === 0}
        onEditButtonPress={() => {
          setShowViewRetailer(false);
          setShowEditRetailer(true);
        }}
      />
    );
  };

  const renderRetailerEditModal = () => {
    return (
      <CreateNewRetailerModal
        onRequestClose={() => {
          setShowEditRetailer(false);
          fetchPublishable();
        }}
        selectedRetailerInfo={selectedDashboardInfo}
        isPublishRetailer={true}
      />
    );
  };
  /** Module wise Edit popup: END */

  const handleThreatEditFromView = (details: any) => {
    toggleThreatViewModal(false);
    toggleThreatEditModal(true);
    setThreatInfo(details);
  };

  const setSelectedModuleInStore = (selectedModule: string) => {
    dispatch({
      type: UPDATED_SELECTED_MODULE,
      payload: selectedModule,
    });
  };

  useEffect(() => {
    const filteredArrayValue = storeData.modules.filter(
      (value: any) => value.isPublish === true
    );
    const shorterArray = filteredArrayValue
      .slice(0, 10)
      .map((item: any) => item.serviceName);
    // shorterArray.push('Plantix CP Products');
    // shorterArray.push('Plantix Peatid Mapping');
    // console.log();
    setModules(shorterArray);
  }, []);

  /** calling list api as per pagination */
  useEffect(() => {
    resetCurrentPage();
  }, [limit]);

  useEffect(() => {
    if (selectedModule)
      activeTab === 0 ? fetchPublishable() : fetchPublishedHistory();
  }, [currentPage, selectedModule]);

  /**this useEffect to get total number of pages
   * after every change in limit and total pages
   */
  useEffect(() => {
    setPublishableData([]);
    if (currentPage !== 0) {
      setCurrentPage(0);
      return;
    }
    resetCurrentPage();
  }, [
    activeTab,
    successfail,
    searchValue,
    selectedModule,
    selectedFilters,
    selectedSortOptions,
  ]);

  useEffect(() => {
    const successProgress = listPublishProgress.some(
      (item: any) => item.status === "Published"
    );
    if (successProgress) {
      fetchPublishable();
    }
  }, [listPublishProgress]);

  const filterColumnsByModifiedBy = (
    columns: Array<PublishColumnType>,
    showModifiedBy: boolean
  ) => {
    if (!showModifiedBy) {
      return columns.filter((col) => col.fieldName !== "modifiedBy");
    }
    return columns;
  };

  const getColumnsForModule = (
    moduleName: string,
    featureModifiedBy: boolean
  ) => {
    switch (moduleName) {
      case "Retailers & Nurseries":
        return filterColumnsByModifiedBy(
          RetailerNurseriesColumns,
          featureModifiedBy
        );
      case "Plantix Map Product":
        return activeTab === 0
          ? filterColumnsByModifiedBy(
              PlantixPeatidMappingColumns,
              featureModifiedBy
            )
          : filterColumnsByModifiedBy(
              PublishedPeatidMappingHistory,
              featureModifiedBy
            );
      default:
        return activeTab === 0
          ? filterColumnsByModifiedBy(PublishedColumn, featureModifiedBy)
          : filterColumnsByModifiedBy(PublishedHistory, featureModifiedBy);
    }
  };

  const handleModuleChange = async (selectedType: string) => {
    setSearchValue("");
    setCurrentPage(0);
    setSelectedFilters([]);
    setSelectedSortOptions(1);
    setSelectedModuleInStore(selectedType);
    updateSelcetedModule(selectedType);

    try {
      const response: any = await checkFeatureModifiedBy();
      const showModifiedBy = response.data;
      setFeatureModifiedBy(showModifiedBy);

      const columns = getColumnsForModule(selectedType, showModifiedBy);
      setActiveColumns(columns);
    } catch (error) {
      console.error("Error checking feature modified by:", error);
      setFeatureModifiedBy(false);

      const columns = getColumnsForModule(selectedType, false);
      setActiveColumns(columns);
    }
  };

  useEffect(() => {
    if (selectedModule) {
      const columns = getColumnsForModule(selectedModule, !!featureModifiedBy);
      setActiveColumns(columns);
    }
  }, [activeTab, selectedModule, featureModifiedBy]);

  return (
    <TableContainer>
      {props.isLoading && <Loader />}
      {props.stateType && (
        <States type={props.stateType} onActionClick={handleActionClick} />
      )}
      {!props.isLoading && props.data?.length >= 0 && !props.stateType && (
        <div className={classes.cropContainerHelper}>
          <div className={classes.titleAndSearchHelper}>
            <PageTitle title={props.title} />
            {selectedModule &&
              (() => {
                let placeholder = "Search by ";

                if (selectedModule === "Plantix Map Product") {
                  placeholder += "Crop, Disease, CP System Name";
                } else if (
                  selectedModule === "CP Products" ||
                  selectedModule === "Hybrids" ||
                  selectedModule === "Threats" ||
                  selectedModule === "Dashboard Media" ||
                  selectedModule === "Plantix CP Products"
                ) {
                  placeholder += "Language, Title";
                } else if (selectedModule === "Push Notifications") {
                  placeholder += "Language, Title";
                } else if (selectedModule === "Retailers & Nurseries") {
                  placeholder += "Name, Mobile Number";
                } else placeholder += "Title";

                return (
                  <SearchBar
                    placeholderText={placeholder}
                    searchValue={searchValue}
                    onSearchValueChange={handleSearchValueChange}
                    onEnterClick={() => {}}
                    isResetSearchValue={searchValue === ""}
                  />
                );
              })()}
          </div>
          {/* Select Module DD: START */}
          <Box className={classes.moduleDropdown}>
            <Select
              placeholder="Select Module"
              options={modules}
              onChange={handleModuleChange}
              selectedValue={selectedModule}
            />
          </Box>
          {/* Select Module DD: END */}
          {selectedModule && (
            <>
              <Box className={classes.customTabBtmBorder}>
                <Stack>
                  <CustomTabs
                    tabsList={["Data list", "History"]}
                    value={activeTab}
                    onChange={toggleTabView}
                  />
                </Stack>
              </Box>
              <Box className={classes.panelBoxHelper}>
                <RowsPerPage
                  pagSize={limit}
                  onChangeRowsPerPage={(value: any) =>
                    onChangeOfRowsPerPage(value)
                  }
                />
                <FilterAndSort
                  filters={
                    {
                      languageIds: [...selectedFilters],
                      crop: [...selectedFiltersCrop],
                      retailerType: [...selectedFilterRetailerType],
                      retailerCategory: [...selectedFilterRetailerCategory],
                    } as any
                  }
                  sortBy={`${selectedSortOptions}`}
                  onChangeFilters={(properties: any) => {
                    processFilters(properties.languageIds);
                    processFiltersCrop(properties.crop);
                    setSelectedFilterRetailerType(properties.retailerType);
                    setSelectedFilterRetailerCategory(
                      properties.retailerCategory
                    );
                  }}
                  onSort={(sortProps: any) => processSort(sortProps)}
                  showLanguageSelection={
                    handleOptionsFilterAndSort(selectedModule)
                      .showLanguageSelection
                  }
                  showCropSelection={
                    handleOptionsFilterAndSort(selectedModule).showCropSelection
                  }
                  showRetailerCategoryType={
                    handleOptionsFilterAndSort(selectedModule)
                      .showRetailerCategoryType
                  }
                  showRetailerType={
                    handleOptionsFilterAndSort(selectedModule).showRetailerType
                  }
                  showSecondarySort={false}
                  showLastUpdatedOn={activeTab === 0}
                  showPublishOnSort={activeTab === 1}
                  hasFilter={true}
                />
              </Box>
              <div>
                <DataTable
                  actionsType={ACTION_TYPE.TYPE_PUBLISH}
                  // showActionProgress={props.showActionProgress}
                  enableSelection={false}
                  columns={activeColumns}
                  data={publishableData}
                  pageNumber={props.filters.pageNumber}
                  onPageChange={() => {}}
                  onSelectAll={() => {}}
                  onDeselectAll={() => props.onDeselectAll()}
                  onActionClick={handleActionClick}
                  onRowSelect={() => {}}
                  isPublishedEnabled={activeTab === 0}
                  isViewButton={true}
                  isDeleteEnabled={activeTab === 0}
                  selectedPublishModule={selectedModule}
                />
                <DataTableActions
                  actionsType={ACTION_TYPE.TYPE_PUBLISH}
                  pageNumber={currentPage + 1}
                  onPageChange={(selectedPageNo: number) =>
                    setCurrentPage(selectedPageNo - 1)
                  }
                  onActionClick={handleActionClick}
                  showActionProgress={props.showActionProgress}
                  totalpages={Math.ceil(totalRecords / limit)}
                  activeTab={activeTab}
                />
              </div>
            </>
          )}
        </div>
      )}
      {showCreatePushNotification &&
        !successfail.pageFail &&
        !successfail.pageSuccess && (
          <PublishView
            publishDetails={singlePublishRecord}
            isSuccessFail={setSuccessfail}
            onClosePublish={handleCloseCreatePushNotification}
            module={selectedModule}
          />
        )}
      {/* -----------------render view modal page: START------------------- */}
      {showViewNotification && renderViewPushNotificationModal()}
      {/*Crop view Modal */}
      {cropView.id && renderViewCropModal()}
      {/*Hybrid view Modal */}
      {hybridView.id && renderViewHybridModal()}
      {/* {showHybridCreateModal && handleHybridEditFromView()} */}
      {/*Threat view Modal */}
      {showThreatViewModal && renderViewThreatDataModal()}
      {/*Cp Product view Modal */}
      {cpView.id && renderViewCPModal()}
      {/**  * Show Dashboard View  */}
      {showDashboardViewModal && renderDashboardViewModal()}
      {showDashboardEditModal && renderDashboardEditModal()}
      {/*Plantix Cp Product view Modal */}
      {plantixCpView.id && renderViewPlantixCPModal()}
      {plantixMapView.id && renderViewPlantixMapModal()}

      {/* -------------------render edit modal page: START---------------------- */}
      {cropEdit.id && renderCreateEditCrop()}
      {showThreatCreateModal && renderEditThreatModal()}
      {cpEdit.id && renderCreateEditCP()}
      {hybidEdit.id && renderHybridEdit()}
      {showEditRetailer && renderRetailerEditModal()}
      {showViewRetailer && renderRetailerViewModal()}
      {plantixCpEdit.id && renderCreateEditPlantixCP()}
      {/* -------------------render edit modal page: END---------------------- */}
    </TableContainer>
  );
};

export default Published;
