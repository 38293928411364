import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  useField,
} from "formik";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { GetImageURLMappedWithCROP } from "../../../Api/generic_apicalls";
import { StateListType } from "../../../Common/Constants/types";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import MultiSelect from "../../../Common/MultiSelect";
import { Media, UploadFile } from "../../../Common/UploadFile";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { GeneralSchemaValidation } from "../HybridValidationSchema";
import { useStyles } from "../useStyle";
import { UniqueHybridSystemNameValidation } from "../ApiCalls/HybridsAPICalls";
import { GeneralDetails, SELECT_ALL } from "./index";
import {
  ActionType,
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";
import getRandomizeValue from "../../../Utils/randomizer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import RenderField from "../../../Common/RenderField";
import { handleStateChangeUtility } from "../../../Utils/handleSelection";
import { removeImageField } from "../../../Utils/genericUtils";

export type LangObjectType = {
  languageId: number | undefined;
  language: string;
  languageCode: string;
};

export type GeneralDetailsProps = {
  generalDetails: GeneralDetails;
  onNext: Function;
  isEdit: boolean;
  stateList: Array<StateListType>;
  cropsList: Array<any>;
  actionType: string;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  getLatestImgURL: Function;
  baseLanguage?: string;
};

export const MyTextField = ({ label, required = true, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);
  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} ${required ? "*" : ""}`}
      </Typography>
      <TextField
        {...field}
        {...props}
        fullWidth
        style={props.disabled ? { backgroundColor: "#F3F4F6" } : {}}
      ></TextField>
    </>
  );
};

const TextArea = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} *`}
      </Typography>
      <TextareaAutosize
        {...field}
        {...props}
        className={classes.textAreaStyles}
      ></TextareaAutosize>
    </>
  );
};

export const GeneralDetailsForm: React.FC<GeneralDetailsProps> = (
  props: GeneralDetailsProps
) => {
  const classes = useStyles();
  let latestSelectedStates: any = props.generalDetails.states
    ? props.generalDetails.states
    : undefined;
  const [selectedStates, setSelectedStates] = useState<Array<any> | undefined>(
    props.generalDetails.states ? props.generalDetails.states : undefined
  );
  const [stateList, setStateList] = useState<Array<StateListType>>(
    props.stateList
  );
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: undefined,
    language: "",
    languageCode: "",
  });

  const storeData = useSelector((state: RootState) => {
    return {
      primaryLangCode: state.auth.languageCode,
    };
  });

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleStateChangeUtility(
      event,
      stateList,
      setStateList,
      (filteredStates) => {
        setSelectedStates(filteredStates?.length ? filteredStates : []);
        latestSelectedStates = filteredStates?.length ? filteredStates : null;
      },
      SELECT_ALL
    );
  };

  const handleClearStateSelection = (checked: boolean) => {
    handleAllSatesSelection(checked);
  };

  const handleAllSatesSelection = (checked: boolean) => {
    const allStateList: any = stateList;
    allStateList.map((state: any) => {
      state.checked = checked;
    });
    setStateList(allStateList);
    const filteredStates = allStateList.filter(
      (state: StateListType) => state.checked && state.name !== SELECT_ALL
    );
    setSelectedStates(filteredStates.length ? filteredStates : null);
    latestSelectedStates = filteredStates.length ? filteredStates : null;
  };

  const handleLanguageChange = (language: string, values: any) => {
    const langArrData = language.split("##");
    const langData: LangObjectType = {
      languageId: parseInt(langArrData[0]),
      language: langArrData[1],
      languageCode: langArrData[2],
    };
    setSelectedLangObj(langData);
  };
  /** Filed level validation for Image file upload */
  // const validateImage = (value: any) => {
  //   /** If, it is add trans, than ignore Image Validation */
  //   if (props.actionType !== ActionType.ADD_TRANSLATION) {
  //     return genericValidateImage(value);
  //   }
  //   return "";
  // };
  /** Upadting local state variable here,
   * to solve the problem of late update of selectedStates variable
   */

  const uploadFileHandle = (
    values: any,
    data: any,
    _onSubmitProps: FormikHelpers<any>
  ) => {
    const media = values.media.filter((media: any) => media) as Media[];
    const hasFile = media.some((media) => media?.path instanceof File);

    if (hasFile) {
      media.forEach((media, i) => {
        if (media.path instanceof File) {
          GetImageURLMappedWithCROP(
            media.path as File,
            values.systemName,
            ModuleImageUpload.HYBRID,
            values.crop,
            "hybridSystemName",
            (response: any) => {
              data.imageUrl = response.data.imageUrl;
              data.media[i] = {
                ...data.media[i],
                path: response.data.imageUrl,
              };
              props.getLatestImgURL(response.data.imageUrl);
            },
            (error: any) => {
              console.error(error, "ERROR");
              _onSubmitProps.setFieldError(
                "imageUrl",
                "Unable to upload file."
              );
              _onSubmitProps.setSubmitting(false);
              return;
            },
            (i + 1).toString().padStart(2, "0")
          );
        }
      });
      data.media = data.media.filter((media: Media) => media);
      props.onNext(STEPS.STEP2, data);
      _onSubmitProps.resetForm();
    } else {
      data.media = data.media.filter((media: Media) => media);
      props.onNext(STEPS.STEP2, data);
      _onSubmitProps.resetForm();
    }
  };

  useEffect(() => {
    if (selectedStates !== undefined) {
      latestSelectedStates = selectedStates;
      /** Auto selecting the selected states in the DD */
      if (selectedStates) {
        stateList.forEach((eachState: any) => {
          latestSelectedStates.map((selectedState: any) => {
            if (selectedState.name === eachState.name) {
              eachState.checked = true;
            }
          });
        });
        if (latestSelectedStates.length === stateList.length - 1) {
          stateList[0].checked = true;
        }
      }
    }
  }, [selectedStates]);

  useEffect(() => {
    if (props.generalDetails.language) {
      const langData: LangObjectType = {
        languageId: props.generalDetails.languageId
          ? props.generalDetails.languageId
          : undefined,
        language: props.generalDetails.language
          ? props.generalDetails.language
          : "",
        languageCode: props.generalDetails.languageCode
          ? props.generalDetails.languageCode
          : "",
      };
      setSelectedLangObj(langData);
    }
  }, [props.generalDetails.language]);
  return (
    <Formik
      initialValues={{
        media: props.generalDetails.media ?? [],
        language: props.generalDetails.language,
        hybridTitle: props.generalDetails.hybridTitle,
        imageUrl: props.generalDetails.imageUrl,
        systemName: props.generalDetails.systemName,
        crop: props.generalDetails.crop,
        agronomyText_maturity: props.generalDetails.agronomyText_maturity,
        sowingPeriod: props.generalDetails.sowingPeriod,
        spacing_ExpectedYield: props.generalDetails.spacing_ExpectedYield,
        seedRate: props.generalDetails.seedRate,
        plantPopulation: props.generalDetails.plantPopulation,
        rating: props.generalDetails.rating,
        keyBenefitOne: props.generalDetails.keyBenefitOne,
        keyBenefitTwo: props.generalDetails.keyBenefitTwo,
        keyBenefitThree: props.generalDetails.keyBenefitThree,
        states: props.generalDetails.states,
        displayOrder: props.generalDetails.displayOrder,
      }}
      validationSchema={GeneralSchemaValidation}
      onSubmit={(values, _onSubmitProps: any) => {
        let data: any = { ...values };
        data.languageId = selectedLangObj.languageId;
        data.languageCode = selectedLangObj.languageCode;
        /** Unique HybridSystemName Validation method will not execute for edit scenerio,
         * just we need to pass correct value for props.isEdit
         */
        let doSysNmCheck: boolean =
          props.actionType === ActionType.CREATE ||
          (props.actionType === ActionType.ADD_TRANSLATION &&
            values.language !== props.baseLanguage);
        UniqueHybridSystemNameValidation(
          values.systemName,
          selectedLangObj.languageCode,
          values.crop,
          doSysNmCheck,
          (response: any) => {
            if (response && response.statusCode === 200) {
              if (response.data.isExists) {
                props.onNext(STEPS.STEP1, data);
                TriggerToastMessage(
                  response.message
                    ? response.message
                    : `The given Hybrid system name exists already`,
                  "warn"
                );
                return;
              }
              uploadFileHandle(values, data, _onSubmitProps);
            } else if (props.isEdit) {
              uploadFileHandle(values, data, _onSubmitProps);
            }
          },
          (_error: any) => {
            console.error(_error, "ERROR");
            /** Error Handler: System Name Verification API */
            props.onNext(STEPS.STEP1, data);
            TriggerToastMessage(
              `Something is wrong, unable to Verify Hybrid System Name !!`,
              "error"
            );
          }
        );
      }}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form id="my-form">
          <Box className={classes.formContainer}>
            <Box className={classes.formFieldsHelperOne}>
              <LanguageDropdown
                onChangeLanguage={(langData: string) => {
                  handleLanguageChange(langData, { ...values });
                  setFieldValue("language", langData.split("##")[1]);
                }}
                key={getRandomizeValue()}
                selectedLanguage={values.language || selectedLangObj.language}
                isDisabled={props.actionType === ActionType.UPDATE}
                role={"language-select"}
                displayPrimaryLanguage={
                  props.actionType !== ActionType.ADD_TRANSLATION
                }
              />
              <FormHelperText error={true} className="langError">
                <ErrorMessage name="language" />
              </FormHelperText>
            </Box>
            <Box className={classes.formFieldsHelper}>
              <Field
                name="media"
                validate={(value: Media[]) => {
                  if (props.actionType === ActionType.ADD_TRANSLATION)
                    return "";

                  return value?.length === 0 ||
                    value?.every((media) => media == null || media.path === "")
                    ? "Image Required"
                    : "";
                }}
              >
                {(imageProps: any) => {
                  const { form } = imageProps;
                  const { setFieldValue } = form;
                  return (
                    <UploadFile
                      name="imageUrl"
                      acceptedFileTypes={[
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                      ]}
                      maxFileSize={5}
                      imageUrl={""}
                      onChange={(
                        file: File,
                        currentMedia: Partial<Media>[],
                        index: number
                      ) => {
                        const newMedia = [...currentMedia];
                        newMedia[index] = {
                          id: currentMedia[index]?.id,
                          displayOrder: index + 1,
                          type: "IMAGE",
                          path: file,
                        };

                        setFieldValue(`media`, newMedia);
                      }}
                      onRemoveImage={(index, currentMedia) => {
                        const newMedia = removeImageField(index, currentMedia);
                        setFieldValue(`media`, newMedia);
                      }}
                      canRemoveOrReplace={props.canAddNewRecord}
                      multiple={true}
                      media={values.media}
                    />
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="media" />
              </FormHelperText>
              <MyTextField
                name="hybridTitle"
                type="text"
                label="Hybrid Title"
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("hybridTitle", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="hybridTitle" />
              </FormHelperText>
              <Field
                name="systemName"
                validate={
                  props.actionType === ActionType.UPDATE
                    ? null
                    : (value: string) =>
                        genericFormFieldValidation(value, ValidationType.SYS_NM)
                }
              >
                {() => {
                  return (
                    <FormControl
                      // className={classes.formMarginHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">System Name *</Typography>
                      <TextField
                        name="systemName"
                        onChange={(event: any) => {
                          setFieldValue(
                            "systemName",
                            event.target.value.trim().toLocaleUpperCase()
                          );
                        }}
                        defaultValue={values.systemName}
                        value={values?.systemName}
                        disabled={!props.isSystemNameEditable}
                      />
                    </FormControl>
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="systemName" />
              </FormHelperText>
              <Field name="crop">
                {() => {
                  return (
                    <RenderField
                      label={"Crop *"}
                      labelKey={"name"}
                      name={"crop"}
                      onChange={(name: string, value: any) => {
                        setFieldValue("crop", value.name);
                      }}
                      options={props.cropsList}
                      selectedOption={values.crop}
                      value={""}
                      displayRadioButton={true}
                      isDisabled={!props.isSystemNameEditable}
                    />
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="crop" />
              </FormHelperText>

              <MyTextField
                name="agronomyText_maturity"
                type="text"
                label="Agronomy Text/Maturity"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("agronomyText_maturity", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="agronomyText_maturity" />
              </FormHelperText>

              <MyTextField
                name="sowingPeriod"
                type="text"
                label="Sowing Period"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("sowingPeriod", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="sowingPeriod" />
              </FormHelperText>

              <MyTextField
                name="spacing_ExpectedYield"
                type="text"
                label="Spacing/Expected Yield"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("spacing_ExpectedYield", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="spacing_ExpectedYield" />
              </FormHelperText>

              <MyTextField
                name="seedRate"
                type="text"
                label="Seed Rate"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("seedRate", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="seedRate" />
              </FormHelperText>

              <MyTextField
                name="plantPopulation"
                type="text"
                label="Plant Population"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("plantPopulation", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="plantPopulation" />
              </FormHelperText>
              <MyTextField
                name="rating"
                type="text"
                label="Rating"
                fullWidth
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched("rating");
                  if (
                    !event.target.value ||
                    RegExp(/\d/).exec(event.target.value)
                  ) {
                    setFieldValue("rating", event.target.value);
                    return;
                  }
                  event.stopPropagation();
                }}
                value={values.rating || ""}
                defaultValue={values?.rating}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="rating" />
              </FormHelperText>
              <TextArea
                name="keyBenefitOne"
                as="textarea"
                label="Key Benefit One"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("keyBenefitOne", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="keyBenefitOne" />
              </FormHelperText>

              <TextArea
                name="keyBenefitTwo"
                as="textarea"
                label="Key Benefit Two"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("keyBenefitTwo", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="keyBenefitTwo" />
              </FormHelperText>

              <TextArea
                name="keyBenefitThree"
                as="textarea"
                label="Key Benefit Three"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("keyBenefitThree", event.target.value);
                }}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="keyBenefitThree" />
              </FormHelperText>
              <MyTextField
                name="displayOrder"
                type="text"
                label="Display Order"
                disabled={
                  !(
                    props.actionType === ActionType.CREATE ||
                    (props.actionType !== ActionType.CREATE &&
                      storeData.primaryLangCode ===
                        props.generalDetails.languageCode)
                  )
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldTouched("displayOrder");
                  if (
                    /^\d*$/.test(event.target.value) ||
                    event.target.value === ""
                  ) {
                    setFieldValue("displayOrder", event.target.value);
                  }
                  event.stopPropagation();
                }}
                value={values.displayOrder || ""}
                defaultValue={values?.displayOrder}
                required={false}
              />
              <FormHelperText error={true}>
                <ErrorMessage name="displayOrder" />
              </FormHelperText>
              <Field name="states">
                {(districtProps: any) => {
                  const { form } = districtProps;
                  const { setFieldValue } = form;
                  return (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">
                        Available States
                      </Typography>
                      <MultiSelect
                        name="stateId"
                        showClearAll={true}
                        labelKey="name"
                        options={stateList}
                        selectedOptions={
                          selectedStates?.map((state: any) => state.name) || []
                        }
                        onCheckboxChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleStateChange(event);
                          setFieldValue("states", latestSelectedStates);
                        }}
                        onClearSelection={() => {
                          handleClearStateSelection(false);
                          setFieldValue("states", null);
                        }}
                        isDisabled={!props.canAddNewRecord}
                      />
                    </FormControl>
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="states" />
              </FormHelperText>
            </Box>
          </Box>
          <>
            <Divider />
            <div className={classes.formActionFooterBtnHelper}>
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  data-testid="next-btn"
                  variant="contained"
                  color="success"
                >
                  Next
                </Button>
              </Stack>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
